<template>
    <div class="loader_area no_sidebar" v-if="contactCurrentProgressLoader"><quote-loader/></div>
    <div class="report_section" v-else :style="`--var-element-bg-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_color : '#f5f5f5'}20;`">
        <div class="habit_container">
            <div class="report_card">
                <div class="header">
                    <div class="user_info">
                        <img :src="contact.photo" alt="">
                        <div>
                            <h4>{{ contact.name }}</h4>
                            <h3>Started Tracking <span>{{ currentHabit.started_time && currentHabit.started_time.date ? moment.utc(currentHabit.started_time.date).local().format('ll') : '' }}</span></h3>
                        </div>
                    </div>
                    <div class="submission">
                        <h4>{{ (histories.filter(history => history.answers && history.answers.length > 0)).length }} / {{ currentHabit.compliance ? currentHabit.compliance.occurrences : 0 }}</h4>
                        <h3>Submissions</h3>
                    </div>
                </div>
            </div>
            <div class="section_header">
                <h2>Habits</h2>
                <div class="action_area">
                    <label for="show_status" class="switch_option capsule_btn p-0 m-0">
                        <h5>Show Status</h5>
                        <input type="checkbox" id="show_status" :true-value="1" :false-value="0" v-model="showStatus" hidden>
                        <div><span></span></div>
                    </label>
                    <!-- <button type="button" class="pointer" @click="calendarView = ! calendarView">{{ calendarView ? 'List View' : 'Calendar View' }}</button> -->
                </div>
            </div>
            <ul class="tracking_list">
                <li>
                    <h4>Participation Score: <span>{{ currentHabit.compliance ? currentHabit.compliance.percentage.toFixed() : 0 }}%</span></h4>
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">{{ currentHabit.compliance ? currentHabit.compliance.percentage.toFixed() : 0 }}<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="habitTracking.public_settings ? habitTracking.public_settings.element_color : '#1976d2'" :stroke-dasharray="116" :stroke-dashoffset="currentHabit.compliance ? 116 - (currentHabit.compliance.percentage * 1.16) : 0"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Habit Score: <span>{{ currentHabit.overall_progress ? currentHabit.overall_progress.percentage.toFixed() : 0 }}%</span></h4>
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">{{ currentHabit.overall_progress ? currentHabit.overall_progress.percentage.toFixed() : 0 }}<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="habitTracking.public_settings ? habitTracking.public_settings.element_color : '#1976d2'" :stroke-dasharray="116" :stroke-dashoffset="currentHabit.overall_progress ? 116 - (currentHabit.overall_progress.percentage * 1.16 ) : 116"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Best Streak: <span>{{ currentHabit.best_streak ? currentHabit.best_streak.week : 0 }} <small>{{ habitTracking.recurring_type == 2 ? 'Days' : 'Weeks' }}</small></span></h4>
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">{{ currentHabit.best_streak ? currentHabit.best_streak.week : 0 }} <span>{{ habitTracking.recurring_type == 2 ? 'D' : 'W' }}</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="habitTracking.public_settings ? habitTracking.public_settings.element_color : '#1976d2'" :stroke-dasharray="116" :stroke-dashoffset="currentHabit.best_streak ? 116 - ((currentHabit.best_streak.week * 116) / (currentHabit.compliance ? currentHabit.compliance.occurrences : 0)) : 100"/>
                        </svg>
                    </div>
                </li>
            </ul>
            <div class="habit_calendar" v-show="calendarView">
                <v-calendar :first-day-of-week="2" timezone="utc" :attributes="calendarResponses" is-expanded @update:from-page="handleCalendarChange" @dayclick="handleCalendarDayClick">
                    <template #day-popover="{ dayTitle }" v-if="1 == 2">
                        <div class="task_details">
                            <div class="task_header">
                                {{ dayTitle }}
                                <div class="stat" v-if="overallResponses[dateToKey(dayTitle)].goal_achieved">
                                    <i class="trophy fas fa-trophy mr-1 ml-1" title=""></i>
                                </div>
                            </div>
                            <div class="quest_panel">
                                <template v-for="(category, index) of categories" :key="index">
                                    <div class="category_wpr">
                                        <h3 class="sub_header">{{ category.name }}</h3>
                                        <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                            <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                            <div class="details">
                                                <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                                <div class="perform_activity" v-if="habitTracking.recurring_type == 2">
                                                    <div class="activity_wpr">
                                                        <span>Performed habit?</span>
                                                        <div class="grp_wpr">
                                                            <label class="active" :class="overallResponses[dateToKey(dayTitle)].responses[item.id] >= 0 ? 'yes' : 'no'">
                                                                <div class="box_item"><i :class="`fas fa-thumbs-${overallResponses[dateToKey(dayTitle)].responses[item.id] >= 0 ? 'up' : 'down'} mr-2`"></i>{{ overallResponses[dateToKey(dayTitle)].responses[item.id] >= 0 ? 'Yes' : 'No' }}</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="perform_activity" v-if="habitTracking.recurring_type == 3">
                                                    <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                                    <div class="days_wpr">
                                                        <span>Days this week I was compliant!</span>
                                                        <ul class="days">
                                                            <li v-for="(week, index) in weekdays" :key="index">
                                                                <label :class="{ 'active pointer': overallResponses[dateToKey(dayTitle)].responses[item.id] && overallResponses[dateToKey(dayTitle)].responses[item.id].includes(week.value + 1) }">
                                                                    <div class="item_wpr">
                                                                        <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                        <span class="tick_box" :class="{ active: overallResponses[dateToKey(dayTitle)].responses[item.id] && overallResponses[dateToKey(dayTitle)].responses[item.id].includes(week.value + 1) }"></span>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </v-calendar>
                <div class="streaks">
                    <h4>Streaks</h4>
                    <ul>
                        <li v-for="(week, w) of weekIndices" :key="w" :class="{ winner: weeksAchievement[week] == 1 }"><i class="fas fa-trophy"></i></li>
                    </ul>
                </div>
            </div>
            <!-- <ul class="habit_list" v-show="!calendarView">
                <li v-for="(category, index) of categories" :key="index">
                    <h3 class="category_title mt-1">{{ category.name }}</h3>
                    <div v-for="(item, i) of category.habits" :key="i">
                        <div class="habit_wpr">
                            <div class="title">
                                {{ item.description ? item.description : '&nbsp;' }}
                                <div class="right">
                                    <div class="dropdown" @click="requirementToggle = requirementToggle == item.id ? 0 : item.id">
                                        <i class="fas fa-info-circle"></i>
                                        <div class="drp_wrapper" v-if="requirementToggle == item.id" :class="{ active : requirementToggle == item.id }">
                                            <div class="quick_info">
                                                <div class="info_title">Requirement</div>
                                                <p>{{ item.requirements }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="habit_info">
                                <ul>
                                    <li>
                                        <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                            <div class="inner_circle">
                                                <div class="score" style="transform: scale(0.8);">{{ currentHabit[item.id] ? currentHabit[item.id].percentage.toFixed() : 0 }}<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="habitTracking.public_settings ? habitTracking.public_settings.element_color : '#1976d2'" :stroke-dasharray="116" :stroke-dashoffset="currentHabit[item.id] ? 116 - (currentHabit[item.id].percentage * 2) : 116"/>
                                            </svg>
                                        </div>
                                        <h6>Habit Score</h6>
                                    </li>
                                    <li>
                                        <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                            <div class="inner_circle">
                                                <div class="score" style="transform: scale(0.8);">{{ currentHabit[item.id] ? currentHabit[item.id].streak : 0 }}<span>{{ habitTracking.recurring_type == 2 ? 'D' : 'W' }}</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="habitTracking.public_settings ? habitTracking.public_settings.element_color : '#1976d2'" :stroke-dasharray="116" :stroke-dashoffset="currentHabit[item.id] && currentHabit[item.id].scores.length ? (116 - (currentHabit[item.id].streak * 116) / currentHabit[item.id].scores.length) : 116"/>
                                            </svg>
                                        </div>
                                        <h6>Best Streak</h6>
                                    </li>
                                </ul>
                            </div>
                            <div class="habit_footer">
                                <ul class="day_sorting" v-if="currentHabit[item.id]" @mousewheel="scrollFunc($event)">
                                    <template v-for="(answer, week) of currentHabit[item.id].scores" :key="`${item.id}-${week}`">
                                        <li @click="handleWeekDialog(item, week, answer, currentHabit[item.id])" :class="(currentHabit[item.id].goalAchived[week] == 1 || parseInt(currentHabit[item.id].goalAchived[week]) == 0) ? '' : 'disabled'">
                                            <i :class="`far fa-${currentHabit[item.id].goalAchived[week] == 1 ? 'check-square' : 'square'}`"></i> {{ habitTracking.recurring_type == 2 ? 'Day' : 'Week' }} {{ parseInt(week) + 1 }}
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul> -->
            <div class="section_header">
                <h2>Submitted Proofs</h2>
            </div>
            <div class="edit_section" v-if="submittedProofs.data && submittedProofs.data.length == 0">
                <div class="empty_box">
                    <img src="@/assets/images/empty_state.svg">
                    <h4>No Submitted Proofs</h4>
                </div>
            </div>
            <div class="proof_content" v-else>
                <ul class="proof_section">
                    <li v-for="(proof, p) of submittedProofs.data" :key="p">
                        <div class="proof_item">
                            <h6>{{ moment(proof.created_at).format('ll') }}</h6>
                            <div class="img_viewer">
                                <img @click="previewImage(proof.file)" :src="proof.file" alt="">
                                <button type="button" class="lightbox_btn" @click="previewImage(proof.file)"><i class="fas fa-search-plus"></i></button>
                                <a :href="proof.file" download target="_blank" class="download_btn"><i class="fas fa-download"></i></a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="pagination">
                    <pagination v-model="proofParams.page" :pages="submittedProofs.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="section_header pt-4">
                <h2>History</h2>
            </div>
            <div class="result_wpr new">
                <div class="scroll_table history_tbl">
                    <table class="show_header">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>{{ habitTracking.recurring_type == 2 ? 'Day' : 'Week' }} of Scenario</th>
                                <th>Status</th>
                                <th>Entry</th>
                            </tr>
                        </thead>
                        <tbody v-if="histories.length">
                            <tr v-for="(history, h) of histories" :key="h">
                                <td>{{ moment.utc(history.scheduled_at).format('ll') }}</td>
                                <td> {{ habitTracking.recurring_type == 2 ? 'Day' : 'Week' }} {{ histories.length - h }}</td>
                                <td>
                                    <span v-if="history.is_submitted && history.is_edited">Edited After Submission</span>
                                    <span v-else-if="history.is_submitted && moment.duration(moment(history.updated_at).diff(history.scheduled_at)).asHours() >= 12">Submitted Late</span>
                                    <span v-else-if="history.is_submitted">Submitted On Time</span>
                                    <span v-else-if="!history.is_submitted && history.scheduled_at_full && moment.duration(moment().diff(history.scheduled_at_full)).asHours() >= 23" >Missed</span>
                                    <span v-else-if="!history.is_submitted && moment.duration(moment().diff(history.scheduled_at)).asHours() >= 23" >Missed</span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <button @click="previewHistory(history)"><i class="fas fa-search-plus"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="4" class="px-4 text-center">
                                    <div class="empty_box">
                                        <img src="@/assets/images/empty_state.svg">
                                        <h4>No Records Found</h4>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <vue-easy-lightbox :visible="previewImagePanel" :index="0" :imgs="availableImages" @hide="previewImagePanel = false"></vue-easy-lightbox>
        <week-dialog v-model="habitWeekDialog" :habit-tracking="habitTracking" :week-habit="selectedWeekHabit" :week="selectedWeek" :week-answer="selectedWeekAnswer" :streak="currentStreak" />
        <history-preview v-model="habitHistoryDialog" :habit-tracking="habitTracking" :history="selectedHistory" :categories="categories" :answers="answers" />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const WeekDialog = defineAsyncComponent(() => import('@/pages/habit-tracking/components/WeekDialog'))
    const HistoryPreview = defineAsyncComponent(() => import('@/pages/habit-tracking/components/HistoryPreview'))

    import moment from 'moment'

    export default {
        name: 'Habit Report',

        data () {
            return {
                proofParams: {
                    page: 1,
                    last_page: 0,
                    habit_tracking_id: 0,
                    contact_id: 0,
                },
                answers: [],
                categories: [],
                contact: {},
                currentHabit: {},
                histories: [],
                calendarView: true,
                requirementToggle: 0,
                moment,
                previewImagePanel: false,
                availableImages: [],
                selectedWeekHabit: {},
                selectedWeek: '',
                selectedWeekAnswer: 0,
                habitWeekDialog: false,
                habitHistoryDialog: false,
                currentStreak: {},
                calendarResponses: [],
                overallResponses: {},
                weekdays: [
                    { title: 'Monday', label: 'M', value: 1 },
                    { title: 'Tuesday', label: 'T', value: 2 },
                    { title: 'Wednesday', label: 'W', value: 3 },
                    { title: 'Thursday', label: 'T', value: 4 },
                    { title: 'Friday', label: 'F', value: 5 },
                    { title: 'Saturday', label: 'S', value: 6 },
                    { title: 'Sunday', label: 'S', value: 0 },
                ],
                selectedHistory: {},
                weekIndices: [],
                weeksAchievement: [],
                showStatus: 0,
            };
        },

        props: {
            habitTracking: Object,
            contactRelation: Object,
        },

        components: {
            WeekDialog,
            HistoryPreview,
        },

        watch: {
            contactRelation: {
                handler (relation) {
                    const vm = this;
                    const params = { contact_id: relation.contact_id ? relation.contact_id : relation.id, assign_id: relation.deleted_at ? relation.id : '', habit_tracking_id: vm.habitTracking.id };

                    vm.getContactCurrentProgress(params).then((report) => {
                        if (report) {
                            vm.proofParams.habit_tracking_id = vm.habitTracking.id
                            vm.proofParams.contact_id        = relation.contact_id

                            vm.getCurrentHabitProof(vm.proofParams);
                        }
                    });
                },
                deep: true
            },

            contactCurrentProgress (report) {
                const vm = this;

                vm.answers      = report.answers;
                vm.categories   = report.categories;
                vm.contact      = report.contact;
                vm.currentHabit = report.currentHabit;
                vm.histories    = report.histories;
                vm.overallResponses = report.currentHabit.overall_responses;
                vm.weeksAchievement = report.currentHabit.weeks_achievement;

                Object.keys(vm.overallResponses).forEach((date, index) => {
                    const response = vm.overallResponses[date];
                    let classes = 'vc-dot-no-goal';

                    if (response.goal_achieved && (response.submitted && vm.showStatus)) {
                        classes = 'vc-dot-has-goal vc-dot-submitted';
                    } else if (response.goal_achieved && (!response.submitted && vm.showStatus)) {
                        classes = 'vc-dot-has-goal vc-dot-no-submit';
                    } else if (!response.goal_achieved && (response.submitted && vm.showStatus)) {
                        classes = 'vc-dot-no-goal vc-dot-submitted';
                    } else if (response.goal_achieved && (response.submitted && !vm.showStatus)) {
                        classes = 'vc-dot-has-goal';
                    } else if (!response.goal_achieved && (!response.submitted && vm.showStatus)) {
                        classes = 'vc-dot-no-goal vc-dot-no-submit';
                    }

                    const score = {
                                      key: index,
                                      dates: new Date(date),
                                      highlight: {
                                          class: false,
                                          fillMode: 'solid',
                                          contentClass: 'assign_day',
                                      },
                                      dot: {
                                          style: {
                                              backgroundColor: 'transparent',
                                          },
                                          class: classes,
                                      },
                                      popover: {
                                          visibility: 'click',
                                      },
                                      customData: response,
                                  };

                    vm.calendarResponses.push(score);
                });
            },

            showStatus (status) {
                this.calendarResponses = [];

                Object.keys(this.overallResponses).forEach((date, index) => {
                    const response = this.overallResponses[date];
                    let classes = 'vc-dot-no-goal';

                    if (response.goal_achieved && (response.submitted && this.showStatus)) {
                        classes = 'vc-dot-has-goal vc-dot-submitted';
                    } else if (response.goal_achieved && (!response.submitted && this.showStatus)) {
                        classes = 'vc-dot-has-goal vc-dot-no-submit';
                    } else if (!response.goal_achieved && (response.submitted && this.showStatus)) {
                        classes = 'vc-dot-no-goal vc-dot-submitted';
                    } else if (response.goal_achieved && (response.submitted && !this.showStatus)) {
                        classes = 'vc-dot-has-goal';
                    } else if (!response.goal_achieved && (!response.submitted && this.showStatus)) {
                        classes = 'vc-dot-no-goal vc-dot-no-submit';
                    }

                    const score = {
                                      key: index,
                                      dates: new Date(date),
                                      highlight: {
                                          class: false,
                                          fillMode: 'solid',
                                          contentClass: 'assign_day',
                                      },
                                      dot: {
                                          style: {
                                              backgroundColor: 'transparent',
                                          },
                                          class: classes,
                                      },
                                      popover: {
                                          visibility: 'click',
                                      },
                                      customData: response,
                                  };

                    this.calendarResponses.push(score);
                });
            }
        },

        computed: mapState({
            submittedProofs: state => state.habitTrackingModule.submittedProofs,
            contactCurrentProgress: state => state.habitTrackingModule.contactCurrentProgress,
            contactCurrentProgressLoader: state => state.habitTrackingModule.contactCurrentProgressLoader,
        }),

        mounted () {
            const vm = this;
            const params = { contact_id: vm.contactRelation.contact_id ? vm.contactRelation.contact_id : vm.contactRelation.id, assign_id: vm.contactRelation.deleted_at ? vm.contactRelation.id : '', habit_tracking_id: vm.habitTracking.id };

            vm.getContactCurrentProgress(params).then((report) => {
                if (report) {
                    vm.proofParams.habit_tracking_id = vm.habitTracking.id
                    vm.proofParams.contact_id        = vm.contactRelation.contact_id

                    vm.getCurrentHabitProof(vm.proofParams);
                }
            });
        },

        methods: {
            ...mapActions({
                getContactCurrentProgress: 'habitTrackingModule/getContactCurrentProgress',
                getCurrentHabitProof: 'habitTrackingModule/getCurrentHabitProof',
            }),

            handlePagination (page) {
                const vm = this;

                vm.proofParams.page = page;

                vm.getCurrentHabitProof(vm.proofParams);
            },

            previewImage (image) {
                const vm = this;

                vm.previewImagePanel  = true;
                vm.availableImages    = image;
            },

            handleWeekDialog (selectedWeekHabit, selectedWeek, selectedWeekAnswer, streak) {
                const vm = this;

                vm.selectedWeekHabit  = selectedWeekHabit;
                vm.selectedWeek       = selectedWeek;
                vm.selectedWeekAnswer = selectedWeekAnswer;
                vm.habitWeekDialog    = true;
                vm.currentStreak      = streak ? streak : {};
            },

            dateToKey (date) {
                return moment(new Date(date)).format('YYYY-MM-DD');
            },

            previewHistory (history) {
                const vm = this;

                vm.selectedHistory = history;
                vm.habitHistoryDialog = true;
            },

            handleCalendarChange (e) {
                const vm              = this;
                const year            = e.year ? e.year : moment().format('YYYY');
                const month           = e.month ? e.month : moment().format('MM');
                const firstDayOfMonth = moment(`${ year }-${ month }`, 'YYYY-MM-DD');
                let currentDay        = moment(firstDayOfMonth, 'YYYY-MM-DD');
                let weekIndices       = [];

                weekIndices.push(currentDay.isoWeek());

                while(currentDay.month() === firstDayOfMonth.month()) {
                    currentDay.add(1, 'weeks');

                    if (currentDay.month() === firstDayOfMonth.month()) {
                        weekIndices.push(currentDay.isoWeek());
                    }
                }

                vm.weekIndices = weekIndices;
            },

            scrollFunc (e) {
                e.preventDefault();
                e.currentTarget.scrollLeft += e.deltaY;
            },

            handleCalendarDayClick (event) {
                const vm = this;
                const date = event.id;

                const history = vm.histories.find(h => moment(h.scheduled_at).format('YYYY-MM-DD') == date);

                if (history && history.id) {
                    vm.previewHistory(history);
                }
            },
        },
    }
</script>

<style scoped>
    .report_section {
        padding: 50px 0;
        background: #f5f5f5;
    }

    .report_card {
        border-radius: 6px;
        margin-bottom: 50px;
        background: #fff;
    }

    .report_card .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
    }

    .report_card .user_info {
        display: flex;
        text-align: left;
        align-items: center;
    }

    .report_card .user_info img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 15px;
        border: 1px solid #ccc;
    }

    .report_card .header h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 5px;
    }

    .report_card .header h3 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
        color: #32373b;
    }

    .tracking_list li h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #32373b;
    }

    .tracking_list li h4 span {
        font-size: 18px;
        line-height: 23px;
        display: block;
        color: #121525;
        padding-top: 8px;
    }

    .tracking_list .score_circle {
        justify-content: flex-end;
        flex-shrink: 0;
    }

    .tracking_list .score_circle .score {
        white-space: nowrap;
    }

    .result_wpr {
        border-color: #eaeaea;
        margin-top: 20px;
        border-radius: 6px;
        overflow: hidden;
    }

    .history_tbl{
        max-height: 500px;
        overflow-y: scroll;
        border-radius: 8px;
    }

    .history_tbl::-webkit-scrollbar {
        width: 4px;
    }

    .history_tbl::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }

    .history_tbl table thead {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background: #fff;
    }
    .history_tbl table thead:after{
        position: absolute;
        content: '';
        left: 0;
        top: 100%;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

    .result_wpr table th,
    .result_wpr table th:first-child {
        width: auto;
        padding: 10px 18px;
        text-align: left;
    }

    .result_wpr table td,
    .result_wpr table td:first-child{
        width: auto;
        padding: 15px 18px;
        text-align: left;
    }
    .result_wpr table td{
        font-weight: 400;
    }
    .result_wpr table th {
        /* background: #f5f5f5; */
        font-weight: 500;
    }
    .result_wpr table thead tr th:last-child {
        width: 150px;
        text-align: right;
    }
    .result_wpr table tbody tr td:last-child button{
        margin-left: auto;
    }

    .result_wpr table td button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 16px;
        /* background: var(--var-element-bg-color, #D4D4D4);
        color: var(--var-element-text-color, #2C3E50); */
        /* background: #e9e9e9; */
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .habit_list {
        padding: 10px 0;
        flex-wrap: wrap;
    }

    .edit_section {
        margin: 20px 0 40px;
    }

    .proof_content {
        margin: 0 0 30px;
    }

    .proof_section {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .proof_section li {
        padding: 10px;
        flex: 0 1 250px;
    }

    .proof_section .proof_item {
        border: 1px solid #eaeaea;
        border-radius: 6px;
        line-height: 0;
        overflow: hidden;
        width: 100%;
    }

    .proof_section .proof_item h6 {
        font-size: 13px;
        line-height: 16px;
        padding: 12px 15px;
        font-weight: 500;
        text-align: left;
    }

    .proof_section .proof_item img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .habit_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .habit_calendar {
        display: flex;
        flex-wrap: wrap;
        max-width: 900px;
        margin: 0 auto 40px auto;
    }

    .vc-container {
        min-width: 80%;
        padding: 0 20px;
        background: transparent;
    }
    :deep(.habit_calendar .vc-container .vc-day-content){
        background: #efefef;
    }
    :deep(.habit_calendar .vc-container .vc-day .assign_day){
        background: #939393;
    }
    :deep(.habit_calendar .vc-container .vc-day .vc-highlight){
        background-color: #939393 !important;
    }

    .habit_calendar .streaks {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px 0 10px 0;
        width: 20%;
    }

    .habit_calendar .streaks h4 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 0 0 10px;
    }

    .habit_calendar .streaks ul {
        flex: 0 1 335px;
        margin: 52px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 10px 30px;
        border-left: 1px solid #e5e5e5;
    }

    .habit_calendar .streaks ul:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 10px;
        bottom: 10px;
        border-left: 1px solid #d9d9d9;
        z-index: 0;
    }

    .habit_calendar .streaks li {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #fff;
        color: var(--var-element-bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        position: relative;
    }

    .habit_calendar .streaks li i {
        filter: grayscale(1);
        opacity: 0.5;
    }

    .habit_calendar .streaks li.winner {
        border: 1px solid var(--var-element-bg-color);
    }

    .habit_calendar .streaks li.winner i {
        filter: grayscale(0);
        opacity: 1;
    }

    .section_header button {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        padding: 7px 15px;
        border-radius: 5px;
        font-family: 'Inter', sans-serif;
        /* border: 1px solid var(--var-element-bg-color, #D4D4D4); */
        /* background: var(--var-element-bg-color, #D4D4D4); */
        /* color: var(--var-element-text-color, #2C3E50); */
        border: 1px solid #d9d9d9;
        background: #f5f5f5;
        color: #2c3e50;
    }

    .img_viewer {
        position: relative;
    }

    .img_viewer .lightbox_btn {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .img_viewer .download_btn {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .img_viewer:hover .lightbox_btn {
        left: 10px;
    }

    .img_viewer:hover .download_btn {
        right: 10px;
    }

    /* .box_item {
        flex: 1 0 50%;
        padding: 0 10px;
    }*/

    .empty {
        font-size: 20px;
        line-height: 25px;
        font-weight: 300;
        color: #999;
        padding: 30px 20px;
        text-align: center;
    }

    /* :deep(.vc-dot-no-goal) {
        display: none !important;
    } */

    .tracking_list li {
        /* background: var(--var-element-light-color, #D4D4D420); */
        background: #fff;
        /* border: 1px solid #efefef; */
    }
    .tracking_list .score_circle .inner_circle{
        background: #fff;
    }

    /* .result_wpr table th,
    .result_wpr table thead tr,
    .habit_wpr .title {
        background: var(--var-element-light-color, #D4D4D420);
        color: var(--var-element-text-color, #2C3E50);
    } */

    .habit_wpr .right .dropdown i {
        color: var(--var-element-text-color, #2C3E50);
    }
    .quest_panel{
        padding: 25px 15px 5px 15px;
        background: #fbfbfb;
        font-family: 'Inter', sans-serif;
    }
    .task_details .task_info h4{
        color: var(--var-element-text-color);
    }
    .task_details .task_info h4 label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-element-bg-color);
        color: var(--var-element-bg-color);
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin: 2px 12px 0 0;
    }
    .task_details .task_info .details p {
        padding: 0 0 20px 30px;
    }
    .task_details .perform_activity{
        margin-top: 10px;
    }
    .activity_wpr {
        padding: 0 0 0 30px;
        width: 100%;
    }
    .grp_wpr {
        width: 100%;
        display: flex;
        gap: 8px;
        padding: 10px 0;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label.active .box_item, .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }
    .task_details .success_goal {
        display: flex;
        align-items: center;
        border-radius: 20px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        width: 100%;
        padding: 10px 0 25px 30px;
    }
    .task_details .success_goal i{
        color: var(--var-element-bg-color);
        font-size: 12px;
        padding-right:10px;
    }
    .category_title{
        font-size: 20px;
        line-height: 27px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 12px;
        text-align: left;
    }

    :deep(.tick_box.active) {
        background: var(--var-element-bg-color);
    }

    .section_header .action_area{
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .section_header .action_area .switch_option h5 {
        font-size: 11px;
        line-height: 16px;
        margin-right: 10px;
    }

    .section_header .action_area .capsule_btn div {
        flex: 0 0 30px;
        width: 30px;
        height: 16px;
    }

    .section_header .action_area .capsule_btn div span {
        width: 10px;
        height: 10px;
    }

    :deep(.vc-popover-content) {
        display: none;
    }
</style>
